<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title : "Import อะไหล่"
page.description : "นำข้อมูลอะไหล่ เข้าสู่ระบบด้วยไฟล์ Excel"
upload.label : "Upload Excel"
error_only.label : "แสดงที่ผิดพลาดเท่านั้น"
change_only.label : "แสดงที่แก้ไขเท่านั้น"
confirm.label : "นำข้อมูลเข้าระบบ"

import.data.error.title : "ข้อมูลผิดพลาด"
import.data.error : "ข้อมูลที่นำเข้าระบบมีข้อผิดพลาด กรุณาตรวจสอบ"
import.file.error.title : "ไฟล์ผิดพลาด"
import.confirm.title : "ยืนยันการ Import"
import.confirm.content : "คุณยืนยันที่จะนำข้อมูลอะไหล่ เข้าระบบ ? (ไม่สามารถแก้ไขภายหลังได้)"
import.confirm.success.title : "Import เรียบร้อย"
import.confirm.success.content : "ทำการนำข้อมูลอะไหล่เข้าระบบเป็นที่เรียบร้อย"
import.confirm.error.title : "นำเข้าข้อมูลผิดพลาด"

part.field.reference : "Reference"
part.field.name_en : "Eng"

part.field.additional_detail : "รายละเอียดอื่น ๆ"
part.field.has_serial_no.tooltip : "ต้องการให้ระบุ Serial No"
part.field.strict_use.tooltip : "ต้องใช้งานกับ Drone ที่ระบุในรุ่นไว้เท่านั้น"
part.field.status.inactive.tooltip : "ยกเลิกการใช้งานอะไหล่นี้"
part.field.warranty_detail : "รายละเอียดประกัน"

part.field.warranty_duration.no : "ไม่มี"
part.field.additional_warranty.flight : "บิน {value} ชั่วโมง"
part.field.additional_warranty.charge : "ชาร์ต {value} รอบ"


</i18n>
<template>
<div class="page page-padding">
	<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
	<a-alert v-if="hasErrors" type="error"
		style="margin-bottom:8px;"
		:message="errorMessageTitle"
		:description="errorMessage"
		show-icon/>

	<div class="myform-action-pane">
		<a-upload
			:disabled="uploading"
			accept=".xlsx"
			:show-upload-list ="false"
			:before-upload="uploadFile">
			<a-button :loading="uploading"><a-icon type="upload" />{{$t('upload.label')}}</a-button>
		</a-upload>
	</div>
	<a-card v-if="importSuccess" :bordered="false" :loading="uploading">
		<div style="margin-bottom:8px;">
			<a-checkbox v-model="filter.errorOnly">
				{{$t('error_only.label')}}
			</a-checkbox>
			<a-checkbox v-model="filter.changeOnly">
				{{$t('change_only.label')}}
			</a-checkbox>
		</div>
		<a-button :disabled="!canConfirmImport" class="btn-page-action"
			type="primary"
			@click="handleConfirmImport">
			{{$t("confirm.label")}}
		</a-button>
		<a-table
			bordered
			class="mytable no-hover table-import"
			:row-key="record => record.reference"
			:data-source="displayImportParts"
			:pagination="{ pageSize: 25 ,currentPage: currentPage }"
			size="small">
			<a-table-column
				:title="$t('common.table.column.excel_row')" data-index='excel_row'
				:width="50"
				align="center">
				<template slot-scope="excelRow,record">
					{{excelRow}}
					<ImportNoticeIcon :model="record" name="excel_row" />
				</template>
			</a-table-column>
			<a-table-column
				:width="120"
				:title="$t('part.field.reference')" data-index='reference'>
				<template slot-scope="reference,record">
					{{reference}}
					<span v-if="record.mode === 'new'" class="import-new">N</span>
					<ImportNoticeIcon :model="record" name="reference" />
				</template>
			</a-table-column>
			<a-table-column
				:width="120"
				:title="$t('part.field.sku')" data-index='sku'>
				<template slot-scope="sku,record">
					{{sku}}
					<ImportNoticeIcon :model="record" name="sku" />
				</template>
			</a-table-column>

			<a-table-column
				:title="$t('common.table.column.detail')" data-index='name'
				:width="250">
				<template slot-scope="name,record">
					<div>{{record.name_th}}</div>
					<div class="table-description">
						<b>{{$t('part.field.name_en')}} : </b>
						{{record.name | emptyCheck("-")}}
						<ImportNoticeIcon :model="record" :name="['name_th','name','part_type']" />
					</div>
					<div class="table-description">
						<b>{{$t('part.field.part_type')}} : </b>
						{{$tenum('part_type',record.part_type)}}
					</div>
				</template>
			</a-table-column>
			<a-table-column
				align="right"
				:width="100"
				:title="$t('part.field.cost_per_unit')" data-index='cost_per_unit' >
				<template slot-scope="costPerUnit,record">
					{{costPerUnit | price(true)}}
					<ImportNoticeIcon :model="record" name="cost_per_unit" />
				</template>
			</a-table-column>
			<a-table-column
				align="right"
				:width="100"
				:title="$t('part.field.price_per_unit')" data-index='price_per_unit'>
				<template slot-scope="pricePerUnit,record">
					{{pricePerUnit | price(true)}}
					<ImportNoticeIcon :model="record" name="price_per_unit" />
				</template>
			</a-table-column>

			<a-table-column
				:title="$t('part.field.warranty_detail')">
				<template slot-scope="record">
					<div v-if="record.warranty_duration > 0">
						{{getDisplayWarrantyDuration(record)}}
					</div>
					{{getDisplayAdditionalWarranty(record)}}
					<ImportNoticeIcon :model="record" :name="['warranty_duration','warranty_type','warranty_value']" />
				</template>
			</a-table-column>
			<a-table-column :title="$t('part.field.additional_detail')">
				<template slot-scope="record">
					<MyTooltip
						v-show="record.has_serial_no"
						:title="$t('part.field.has_serial_no.tooltip')">
						<a-tag  color="#ffb7b2">Serial</a-tag>
					</MyTooltip>
					<MyTooltip
						v-show="record.strict_use"
						:title="$t('part.field.strict_use.tooltip')">
						<a-tag color="#c7ceea">Match Model</a-tag>
					</MyTooltip>
					<ImportNoticeIcon :model="record" :name="['has_serial_no','strict_use']" />
				</template>
				</a-table-column>

			<a-table-column
				align="center"
				:width="100"
				:title="$t('part.field.data_date')" data-index='data_date'>
				<template slot-scope="dataDate,record">
					{{ dataDate | date('L')}}
					<ImportNoticeIcon :model="record" name="data_date" />
				</template>
			</a-table-column>
			<a-table-column
				:title="$t('part.field.remark')"
				data-index="remark"
				:width="50"
				align="center">
				<template slot-scope="remark">
					<MyTooltip v-if="$notEmpty(remark)" :mouse-enter-delay="0.3"
						:destroy-tooltip-on-hide="true">
						<div slot="title" v-trim class="multiline">
							{{remark}}
						</div>
						<a-icon type="message" class="cursor-pointer"/>
					</MyTooltip>
				</template>
			</a-table-column>
		</a-table>
	</a-card>
</div>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
import PageMixin from "@mixins/PageMixin.vue"
import {Upload,Table,Tag,Checkbox} from "ant-design-vue"
import ImportNoticeIcon from "@components/common/ImportNoticeIcon.vue"
import axios from "axios"
import {isVarEmpty} from "@utils/stringUtil"
import { mapActions } from 'vuex'
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		"a-upload" : Upload,
		"a-table" : Table,
		"a-table-column" : Table.Column,
		"a-tag" : Tag,
		"a-checkbox" : Checkbox,
		ImportNoticeIcon, MyTooltip ,
	} ,
	mixins : [PageMixin] ,
	data() {
		return {
			uploading : false,
			hasErrors : false,
			importSuccess : false,
			importParts : [] ,
			errorMessageTitle : null,
			errorMessage : null,
			currentPage : 1,
			filter : {
				errorOnly : false,
				changeOnly : false,
			}
		}
	} ,
	computed : {
		displayImportParts() {
			return this.importParts.filter((part) => {
				let show = true;
				if (this.filter.errorOnly) {
					show = !isVarEmpty(part.errors);
				}
				if (this.filter.changeOnly) {
					show = part.mode === 'new' || !isVarEmpty(part.changes);
				}
				return show;
			})
		} ,
		canConfirmImport() {
			if (!this.importSuccess || this.hasErrors)
				return false;
			for(const part of this.importParts) {
				if (part.mode === 'new' || !isVarEmpty(part.changes))
					return true;
			}
			return false;
		}
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},

	methods : {
		...mapActions('app',['collapsedSideMenu']) ,
		...mapActions('drone',['fetchParts']),
		uploadFile(file) {
			this.uploading = true;
			const formData = new FormData();
			formData.append('file',file);

			axios.post('/api/parts/import',formData).then((response)=>{
				this.importParts = response.data.data.import_data;
				this.hasErrors = response.data.data.has_errors;
				this.importSuccess = true;
				this.filter.errorOnly = false;
				this.filter.changeOnly = false;
				this.currentPage = 1;
				if (this.hasErrors) {
					this.errorMessageTitle = this.$t('import.data.error.title');
					this.errorMessage = this.$t('import.data.error')
				}
				this.collapsedSideMenu(true);
			}).catch((error)=>{
				this.hasErrors = true;
				this.errorMessageTitle = this.$t('import.file.error.title');
				this.errorMessage = ApiError.getDisplayErrorMessage(this,error);
			}).finally(()=>{
				this.uploading = false
			})
			return false;
		} ,
		getDisplayWarrantyDuration(part) {
			if (part.warranty_duration > 0) {
				return part.warranty_duration + " "+this.$t('common.day');
			} else {
				return "";
			}
		} ,
		getDisplayAdditionalWarranty(part) {
			if (part.warranty_type && part.warranty_value > 0) {
				switch(part.warranty_type) {
					case "flight" :
						return this.$t('part.field.additional_warranty.flight',{'value' : part.warranty_value});
					case "charge" :
						return this.$t('part.field.additional_warranty.charge',{'value' : part.warranty_value});
				}
			}
			return ''
		} ,
		handleConfirmImport() {
			if (this.hasErrors)
				return;

			this.$confirm({
				title : this.$t('import.confirm.title') ,
				content : this.$t('import.confirm.content') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk : () => {
					this.showPageLoading()
					const successTitle = this.$t('import.confirm.success.title');
					const successContent = this.$t('import.confirm.success.content');
					const sendParts = this.importParts.filter((part) => part.mode === 'new' || !isVarEmpty(part.changes));
					axios.post("/api/parts/import-confirm",sendParts).then((response)=>{
						this.fetchParts()
						this.$notification.success({
							message :  successTitle,
							description : successContent,
						});
						this.$router.push({name: 'part'})
					}).catch((error) => {
						this.hasErrors = true;
						this.errorMessageTitle = this.$t('import.confirm.error.title');
						this.errorMessage = ApiError.getDisplayErrorMessage(this,error)
					}).finally(()=>{
						this.hidePageLoading();
					})
				} ,
				onCancel() {}
			});
		}
	}
}
</script>
<style lang="less" scoped>
.table-import .import-new {
	display : inline-block;
	color : @white;
	background-color : @success-color;
	font-size: 0.6em;
	width : 16px;
	height : 16px;
	vertical-align: top;
	text-align : center;
	padding-top : 2px;
	margin-top : 0.5px;
	border-radius : 50%;
}
</style>
